import React from 'react'
import { connect } from 'react-redux'
import ContactForm from '../components/ContactForm';
import Map from '../components/Map';
import { getSiteData } from "../redux/selectors";

export const Contact = ({ siteData }) => {
    return (
        <div id="section4" className="section">
            <div className="slide">
                <div className="text-dark mx-5 d-flex justify-content-center" style={{marginTop: 75, marginBottom: 75}}>
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            width: "100%",
                            minHeight: "100vh"
                        }}
                    >
                        <div className="row" style={{width: "90vw"}}>
                            <div className="col-sm-6">
                                <ContactForm />
                            </div>
                            <div className="col-sm-6">
                                <Map />
                                <div style={{textAlign: "center"}}>
                                    <div className='m-3'>
                                        <a href={siteData?.instagram} target="_blank" className='me-2'>
                                            <img src="/img/instagram.png" width={48}/>
                                        </a>
                                        <a href={siteData?.facebook} target="_blank" className='mx-2'>
                                            <img src="/img/facebook.png" width={48}/>
                                        </a>
                                        <a href={siteData?.twitter} target="_blank" className='mx-2'>
                                            <img src="/img/twitter.png" width={48}/>
                                        </a>
                                    </div>
                                    <div className='m-3 py-1 d-flex align-items-center justify-content-center'>
                                        <i className='fa fa-phone me-3' style={{fontSize: 32}}></i>
                                        <b>{siteData?.phoneNumber}</b>
                                    </div>
                                    <div className='m-3 py-1 d-flex align-items-center justify-content-center'>
                                        <i className='fa fa-phone me-3' style={{fontSize: 32}}></i>
                                        <b>{siteData?.secondPhone}</b>
                                    </div>
                                    <div className='m-3 d-flex align-items-center justify-content-center'>
                                        <i className='fa fa-envelope-open me-3' style={{fontSize: 32}}></i>
                                        <b>
                                            <a
                                                href={`mailto:${siteData?.email}`}
                                                className="text-dark"
                                                style={{
                                                    textDecoration: "none"
                                                }}
                                            >
                                                {siteData?.email}
                                            </a>
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    siteData: getSiteData(state)
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);