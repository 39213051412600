import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

export const MobileWelcome = (props) => {
    const [srcIndex, setSrcIndex] = useState(0);

    const imgSrcs = [
        "/img/acceuil1.png",
        "/img/acceuil2.png",
        "/img/acceuil3.png",
        "/img/acceuil4.png",
    ];
    const imgText = [
        "Excellent Tutorat fait du soutien aux éLèves",
        "Excellent Tutorat accompagne vos enfants",
        "Nous conduisons vos enfants à l’excellence",
        "Nos enseignant(e)s qualifié(e)s sont à la disposition de vos enfants"
    ];

    useEffect(() => {
        const x = setInterval(() => {
            if (srcIndex < 0 || srcIndex == imgSrcs.length-1) {
                setSrcIndex(0);
            } else {
                setSrcIndex(srcIndex + 1);
            }
        }, 3000)

        return () => clearInterval(x);
    })

    return (
        <div id="section1">
            <img src={imgSrcs[srcIndex]} className="img-fluid" style={{aspectRatio: 1.5, width: "100vw"}}/>
            <div
                className="d-flex justify-content-center align-items-center text-light"
                style={styles.messageContainer}
            >
                <h5><b>{imgText[srcIndex]}</b></h5>
            </div>
        </div>
    );
};

const styles = {
    messageContainer: {
        backgroundColor: "rgba(0, 0, 0, 1)",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 5,
        textAlign: "center"
    }
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileWelcome);