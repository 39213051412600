import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { endLoading, signInUser, signOutUser, startLoading, updateBlogData, updateSiteData } from "./actions";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";

export const loadData = (status) => async dispatch => {
    try {
        if (status === true) {
            dispatch(endLoading());
        } else {
            dispatch(startLoading());

            const blogCollection = collection(db, "blogs");
            let posts = [];
            let postIndex = 0;
            getDocs(blogCollection)
                .then((data) => {
                    posts = data.docs
                        .map(post => post.data())
                        .sort((a, b) => a.postId - b.postId)

                    dispatch(updateBlogData(posts))
                })
                .catch(error => dispatch(console.log(error)));

            getDoc(doc(db, "siteData", "meta"))
                .then((data) => {
                    dispatch(updateSiteData(data.data()))
                })
                .catch(error => dispatch(console.log(error)));
        }
    } catch (e) {
        dispatch(console.log(e));
    }
};

export const createPost = (postData, resetFieldsFunc=null) => async dispatch => {
    try {
        const postDoc = doc(db, "blogs", postData?.docName);
        setDoc(postDoc, postData?.data)
            .then((data) => {
                const backdrop = document.getElementsByClassName("modal-backdrop")?.item(0);
                backdrop.className = "modal-backdrop fade";
                backdrop.style.display = "none";
                const modal = document.getElementById("blogModal");
                modal.style.className = "modal fade scrollingDiv";
                modal.style.display = "none";
                modal["aria-hidden"] = true;
                modal["aria-modal"] = undefined;
                modal["role"] = undefined;

                dispatch(loadData(false));
                resetFieldsFunc();
            })
            .catch(error => console.log(error))
    } catch (e) {
        dispatch(console.log(e));
    }
}

export const logoutUser = () => async dispatch => {
    try {
        auth.signOut()
            .then(() => {
                dispatch(signOutUser());
            })
            .catch((error) => dispatch(console.error(error)));
    } catch (e) {
        dispatch(console.log(e));
    }
}

export const loginUser = (data, resetFieldsFunc=null, errorUpdateFunc=console.log) => async dispatch => {
    try {
        signInWithEmailAndPassword(auth, data.email, data.password)
        .then((userCredential) => {
            getDoc(doc(db, "users", userCredential.user.email))
                .then((data) => {
                    const backdrop = document.getElementsByClassName("modal-backdrop")?.item(0);
                    backdrop.className = "modal-backdrop fade";
                    backdrop.style.display = "none";
                    const modal = document.getElementById("loginModal");
                    modal.style.className = "modal fade scrollingDiv";
                    modal.style.display = "none";
                    modal["aria-hidden"] = true;
                    modal["aria-modal"] = undefined;
                    modal["role"] = undefined;
                    
                    dispatch(signInUser({
                        username: userCredential.user.email,
                        email: userCredential.user.email,
                        ...data?.data()
                    }));

                    resetFieldsFunc();
                });
        })
        .catch((error) => {
            switch(error.code) {
                case "auth/invalid-email":
                    errorUpdateFunc("L'adresse email n'est pas valide");
                    break;
                case "auth/user-not-found":
                    errorUpdateFunc("Pas de compte associée avec l'adresse email");
                    break;
                case "auth/wrong-password":
                    errorUpdateFunc("Le mot de passe n'est pas correcte");
                    break;
                default:
                    errorUpdateFunc(`Erreur non-identifiée: ${error.code}`);
                    dispatch(console.log(error))
            }
        });
    } catch (e) {
        dispatch(console.log(e));
    }
}

export const registerUser = (data, resetFieldsFunc=null, errorUpdateFunc=console.log) => async dispatch => {
    try {
        createUserWithEmailAndPassword(auth, data?.email, data?.password)
            .then((userData) => {
                if (data.parentName === null) delete data.parentName;
                delete data.password;
                setDoc(
                    doc(db, "users", userData.user.email), data
                )
                .then(() => {
                    const backdrop = document.getElementsByClassName("modal-backdrop")?.item(0);
                    backdrop.className = "modal-backdrop fade";
                    backdrop.style.display = "none";
                    const modal = document.getElementById("registerModal");
                    modal.style.className = "modal fade scrollingDiv";
                    modal.style.display = "none";
                    modal["aria-hidden"] = true;
                    modal["aria-modal"] = undefined;
                    modal["role"] = undefined;

                    resetFieldsFunc();

                    
                    dispatch(signInUser({
                        username: userData.user.email,
                        email: userData.user.email
                    }))
            })
                .catch((error) => dispatch(console.error(error)));
                
            })
            .catch((error) => {
                switch (error.code) {
                    case "auth/email-already-in-use":
                        errorUpdateFunc("L'adresse email est déja en utilisation");
                        break;
                    case "auth/weak-password":
                        errorUpdateFunc("Le mot de passe est trop faible - 7+ lettres et 1+ chiffres");
                        break;
                    default:
                        errorUpdateFunc(`Erreur non-identifiée: ${error.code}`);
                        dispatch(console.error(error))
                }
            });
    } catch (e) {
        dispatch(console.log(e));
    }
}