import { createSelector } from "reselect";

export const getLoadingState = state => state.todos.isLoading;
export const getUserData = state => state.todos.user;

export const getState = state => state.todos;

export const getBlogData = state => state.todos.blogPosts;
export const getBlogPostCount = state => state.todos.blogPosts?.length;

export const getSiteData = state => state.todos.siteData;