import React from 'react'
import { connect } from 'react-redux'

export const BlogNavbarButton = ({ post, setPost, isActive, displayPost }) => {
    return (
        // <li className="nav-item" style={{width: "inherit"}}>
        //     <button
        //         className={(isActive) ? 
        //             "nav-link btn btn-dark text-light active" :
        //             "nav-link btn btn-dark text-light"
        //         }
        //         style={styles.manuLink}
        //         href="#"
        //         onClick={() => setPost(postId-1)}
        //     >
        //         {title}
        //     </button>
        // </li>
        <div
            className="px-4 postButton"
            style={{
                width: "90%",
                height: 125,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: 20,
                overflow: "clip",
                paddingBottom: 10,
                paddingTop: 10,
                margin: "15px 0px",
                textAlign: "left",
                cursor: "pointer"
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = "rgba(255, 255, 255, 0.2)"}
            onMouseLeave={(e) => e.target.style.backgroundColor = "rgba(255, 255, 255, 0.1)"}
            onClick={() => displayPost(post?.postId)}
        >
            <h5 style={{pointerEvents: "none"}}><p><strong>{post?.title}</strong></p></h5>
            <p style={{fontSize: 9, margin: "-10px 0px 10px", pointerEvents: "none"}}><strong>{post?.date}</strong></p>
            <p style={{height: "40%", overflow: "clip", pointerEvents: "none"}} dangerouslySetInnerHTML={{
                        __html: 
                            post?.content + "<br/><br/>&nbsp;"
            }}></p>
        </div>
    );
};

const styles = {
    manuLink: {
        height: 50,
        width: "inherit",
        borderRadius: "0%"
    }
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogNavbarButton);