import React, { useState } from 'react'
import { connect } from 'react-redux'
import { loginUser } from '../redux/thunks';
import { Login } from '@microsoft/mgt-react';
import { authProvider } from '../msgraph';

export const LoginModal = ({ loginThunk }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");

    const resetFields = () => {
        setEmail("");
        setPassword("");
        setLoginError("")
    };

    const login = () => {
        setLoginError("")

        loginThunk(
            {
                email: email,
                password: password
            },
            resetFields,
            setLoginError
        );
    }

    const validateEmail = () => {
        if (email === "") return false;
        else return true; 
    }
    const validatePassword = () => {
        if (password === "") return false;
        else return true; 
    }

    const canLogin = () => {
        return (
            validateEmail() && validatePassword()
        );
    };

    const hideModal = (e) => {
        const modal = document.getElementById("myModal");

        if (e.target === modal) {
            modal.style.display = "none";
            setEmail("");
            setPassword("");
        }
    }

    return (
        <div id="loginModal" className='modal fade scrollingDiv'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <span style={{
                            textAlign: "center",
                            width: "90%",
                            color: "red",
                            fontWeight: "bold",
                        }}>
                            <small>{loginError}</small>
                        </span>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => resetFields()}></button>
                    </div>
                    <div className='modal-body' id="modalContent">
                        <div className='d-grid'>
                            <div className="form-floating my-2 mx-1">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="loginEmail"
                                    placeholder="Email"
                                    name="loginEmail"
                                    style={{
                                        opacity: 0.8
                                    }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter" && canLogin()) login()
                                    }}
                                />
                                <label htmlFor="loginEmail">Addresse Email</label>
                            </div>
                            <div className="form-floating my-2 mx-1">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="loginPassword"
                                    placeholder="Mot de passe"
                                    name="loginPassword"
                                    style={{
                                        opacity: 0.8
                                    }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter" && canLogin()) login()
                                    }}
                                />
                                <label htmlFor="loginPassword">Mot de passe</label>
                            </div>
                            <div className='d-flex'>
                                <Login lang='fr'/>
                                <button
                                    className={canLogin() ?
                                        'btn btn-warning my-2 p-2' : 'btn btn-secondary my-2 p-2 disabled'
                                    }
                                    onClick={() => login()}
                                    style={{
                                        width: "75%"
                                    }}
                                >
                                    Connexion
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    loginThunk: (data, resetFieldsFunc, errorUpdateFunc) => dispatch(loginUser(data, resetFieldsFunc, errorUpdateFunc))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);