export const LOADING_START = "LOADING_START";
export const startLoading = () => ({
    type: LOADING_START
});

export const LOADING_END = "LOADING_END";
export const endLoading = () => ({
    type: LOADING_END
});

export const USER_SIGN_IN = "USER_SIGN_IN";
export const signInUser = (user) => ({
    type: USER_SIGN_IN,
    payload: { user }
});

export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const signOutUser = () => ({
    type: USER_SIGN_OUT,
});

export const BLOG_DATA_LOADED = "BLOG_DATA_LOADED";
export const updateBlogData = (data) => ({
    type: BLOG_DATA_LOADED,
    payload: { data }
});

export const SITE_META_LOADED = "SITE_META_LOADED";
export const updateSiteData = (data) => ({
    type: SITE_META_LOADED,
    payload: { data }
});