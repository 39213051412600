import React, { useState } from 'react'
import { connect } from 'react-redux'
import emailjs from '@emailjs/browser';
import { getSiteData } from '../redux/selectors';
import env from '../env.json'


export const ContactForm = ({ siteData }) => {
    const email_validator = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const resetFields = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        setSubject("");
    }

    const validateFirstName = () => {
        if (firstName === "") return false;
        else return true; 
    }
    const validateLastName = () => {
        if (firstName === "") return false;
        else return true; 
    }
    const validateEmail = () => {
        return email_validator.test(email);
    }
    const validateSubject = () => {
        if (subject === "") return false;
        else return true; 
    }
    const validateMessage = () => {
        if (message === "") return false;
        else return true; 
    }

    const canMessage = () => {
        return (
            validateFirstName() &&
            validateLastName() &&
            validateEmail() &&
            validateSubject() &&
            validateMessage()
        );
    };

    const sendEmail = () => {
        emailjs.send(
            "service_9ls3dgf",
            "template_z7ne047",
            {
                firstName: firstName,
                lastName: lastName,
                email: email,
                subject: subject,
                message: message,
            },
            "50Yi-e60N6UynjRyw"
        )
        .then(() => {
            fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${env.token}`
                },
                body: {
                    message: {
                        subject: subject,
                        body: {
                            contentType: "Text",
                            content: `${firstName} ${lastName}\n${email}\n\n${message}`
                        },
                        toRecipients: [
                            {
                                emailAddress: {
                                    address: siteData?.email
                                }
                            }
                        ],
                        saveToSentItems: false
                    }
                }
            })
            .then(() => {
                resetFields();
            })
            .catch(e => console.log(e))
        })
        .catch(e => console.log(e));
    }

    return (
        <div className="container-fluid">
            <div className="form-floating my-3 mx-1">
                <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Prenom"
                    name="lastName"
                    style={{
                        opacity: 0.8
                    }}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <label htmlFor="lastName">Prenom</label>
            </div>
            <div className="form-floating my-3 mx-1">
                <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="Nom"
                    name="firstName"
                    style={{
                        opacity: 0.8
                    }}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <label htmlFor="firstName">Nom</label>
            </div>
            <div className="form-floating my-3 mx-1">
                <input
                    type="email"
                    className="form-control"
                    id="contactEmail"
                    placeholder="Email"
                    name="contactEmail"
                    style={{
                        opacity: 0.8
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="contactEmail">Adresse Email</label>
            </div>
            <div className="form-floating my-3 mx-1">
                <input
                    type="text"
                    className="form-control"
                    id="subject"
                    placeholder="Nom"
                    name="subject"
                    style={{
                        opacity: 0.8
                    }}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />
                <label htmlFor="subject">Sujet</label>
            </div>
            <div className="container-fluid">
                <label htmlFor="message"><b>Message</b></label>
                <textarea
                    className="form-control mt-1"
                    rows={5}
                    id="message"
                    name="message"
                    style={{
                        resize: "none",
                        opacity: 0.8
                    }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>
            <div className="container-fluid d-grid mt-3">
                <button
                    className={(canMessage()) ?
                        "btn btn-primary btn-block" : "btn btn-secondary btn-block disabled"
                    }
                    type="button"
                    onClick={() => sendEmail()}
                >Envoyer</button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    siteData: getSiteData(state)
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);