import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from "react-router-dom"
import { auth } from '../firebase';
import { getSiteData, getUserData } from '../redux/selectors';
import { logoutUser } from '../redux/thunks';

export const Navbar = ({ logoutThunk, siteData }) => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const x = setInterval(() => {
            setLoggedIn(auth.currentUser !== null);
        });

        return () => clearInterval(x);
    })

    const sectionMapping = [
        "section1",
        "section2",
        "section3",
        "section4"
    ];
    const [activeSection, setActiveSection] = useState("section1");

    const getLinkClass = (section) => {
        if (section === activeSection) {
            return "nav-link active section-link";
        } else {
            return "nav-link section-link";
        }
    };

    const getLinkStyle = (section) => {
        if (section === activeSection) {
            return {
                fontWeight: "bold"
            };
        } else {
            return {
                fontWeight: "normal"
            };
        }
    }

    useEffect(() => {
        const x = setInterval(() => {
            const index = window.fullpage_api.getActiveSection().index();
            const sectionString = sectionMapping[index]

            setActiveSection(sectionString);
        }, 500)

        const links = Array.from(
            document.getElementsByClassName("section-link")
        );

        links.forEach(link => {
            link.onclick = (e) => {
                e.preventDefault();
                const section = parseInt(
                    e.target.id.replace("linkToSection", "")
                );
                const sectionString = `section${section}`;

                setActiveSection(sectionString);

                window.fullpage_api.moveTo(section);
            };
        })

        const logout = document.getElementById("logout");
        logout.onclick = () => {
            logoutThunk();
        }
        render();

        return () => clearInterval(x);
    }, [activeSection])

    const render = () => {
        return (
            <nav className="navbar navbar-expand-sm navbar-light px-5 fixed-top" style={{
                backgroundColor: "rgba(255, 255, 255, 0.4)",
                fontSize: 12,
                height: "70px",
                paddingTop: 15
            }}>
                <div className="container-fluid">
                    <span
                        className="navbar-brand"
                        style={{fontWeight: "bold", textAlign: "center"}}
                    >
                        <img src="/img/logo.png" width="50px" />
                        <p style={{fontSize: 8}}><b>Excellent Tutorat</b></p>
                    </span>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li data-menuanchor="section1" className="nav-item">
                                <NavLink
                                    id="linkToSection1"
                                    className={getLinkClass("section1")}
                                    style={getLinkStyle("section1")}
                                >
                                    Accueil
                                </NavLink>
                            </li>
                            <li data-menuanchor="section2" className="nav-item">
                                <NavLink
                                    id="linkToSection2"
                                    className={getLinkClass("section2")}
                                    style={getLinkStyle("section2")}
                                >
                                    Qui Sommes Nous?
                                </NavLink>
                            </li>
                            <li data-menuanchor="section3" className="nav-item">
                                <NavLink
                                    id="linkToSection3"
                                    className={getLinkClass("section3")}
                                    style={getLinkStyle("section3")}
                                >
                                    Nos Services
                                </NavLink>
                            </li>
                            <li data-menuanchor="section4" className="nav-item">
                                <NavLink
                                    id="linkToSection4"
                                    className={getLinkClass("section4")}
                                    style={getLinkStyle("section4")}
                                >
                                    Contactez Nous
                                </NavLink>
                            </li>
                            <li className='nav-tem'>
                                <div className='mx-2 d-flex align-items-center justify-content-center' style={{
                                    marginTop: 12
                                }}>
                                    <i className='fa fa-phone me-1' style={{fontSize: 16}}></i>
                                    <b style={{fontSize: 10}}>{siteData?.phoneNumber}</b>
                                </div>
                            </li>
                            <li className='nav-tem'>
                                <div className='mx-2 d-flex align-items-center justify-content-center' style={{
                                    marginTop: 12
                                }}>
                                    <i className='fa fa-phone me-1' style={{fontSize: 16}}></i>
                                    <b style={{fontSize: 10}}>{siteData?.secondPhone}</b>
                                </div>
                            </li>
                            <li className='nav-tem'>
                                <div className='mx-2 d-flex align-items-center justify-content-center' style={{
                                    marginTop: 12
                                }}>
                                    <i className='fa fa-envelope-open me-3' style={{fontSize: 16}}></i>
                                    <b style={{fontSize: 10}}>
                                        <a
                                            href={`mailto:${siteData?.email}`}
                                            className="text-dark"
                                            style={{
                                                textDecoration: "none"
                                            }}
                                        >
                                            {siteData?.email}
                                        </a>
                                    </b>
                                </div>
                            </li>
                            <li className='nav-item me-1'>
                                <a
                                    id="register"className='nav-link btn btn-warning text-light'
                                    style={{
                                        opacity: 0.75,
                                        fontSize: 14,
                                        width: 120,
                                        display: (auth.currentUser === null) ? "" : "none"
                                    }}
                                    data-bs-target="#registerModal"
                                    data-bs-toggle="modal"
                                >
                                    <b>S'enregistrer</b>
                                </a>
                            </li>
                            <li className='nav-item me-1'>
                                <a
                                    id="login"
                                    className='nav-link btn btn-light text-dark'
                                    style={{
                                        opacity: 0.75,
                                        fontSize: 14,
                                        width: 120,
                                        display: (auth.currentUser === null) ? "" : "none"
                                    }}
                                    data-bs-target="#loginModal"
                                    data-bs-toggle="modal"
                                >
                                    <b>Connexion</b>
                                </a>
                            </li>
                        </ul>
                        <button
                            id="logout"className='btn btn-danger text-light me-2'
                            style={{
                                opacity: 0.75,
                                fontSize: 14,
                                width: 110,
                                display: (auth.currentUser !== null) ? "" : "none"
                            }}
                            onClick={() => logoutThunk()}
                        >
                            <b>Déconnexion</b>
                        </button>
                        {
                            (auth?.currentUser?.email === "shadow@root.org") &&
                            (
                                <a className="btn btn-dark hAdjust" style={{...styles.blogButton}}
                                    href="/blog"
                                >
                                    <b>Blog</b>
                                    &nbsp;
                                    &nbsp;
                                    <i className="fa fa-book" style={{fontSize: "24px"}}></i>
                                </a>
                            )
                        }
                    </div>
                </div>
            </nav>
        );
    };

    return render();
};

const styles = {
    blogButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.75,
        fontSize: 14,
        width: 120
    }
}

const mapStateToProps = (state) => ({
    user: getUserData(state),
    siteData: getSiteData(state)
});

const mapDispatchToProps = (dispatch) => ({
    logoutThunk: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);