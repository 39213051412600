import React, { useEffect } from 'react'
import { connect } from 'react-redux'
// import ReactFullpage from '@fullpage/react-fullpage';
import Navbar from "../components/Navbar";
import ContactForm from "../components/ContactForm";
import { Map } from "../components/Map";
import Welcome from "../sections/Welcome";
import About from "../sections/About";
import Services from "../sections/Services";
import Contact from '../sections/Contact';

export const HomePage = ({ setActivePage }) => {
    useEffect(() => {
        setActivePage("home");
    })

    return (
        <>
            <Welcome />
            <About />
            <Services />
            <Contact />
            <div id="footer" className="section fixed-bottom"></div>
        </>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);