import React from 'react'
import { connect } from 'react-redux'
import MobileBlogNavbarButton from './MobileBlogNavbarButton';

export const MobileBlogMenu = ({ posts, setPost, targetPost }) => {
    let keyIndex = 0;
    return (
        <div className="offcanvas offcanvas-start text-bg-dark" id="sidebar" style={{
            width: "60vw"
        }}>
            <div className="offcanvas-header">
                <h1 className="offcanvas-title">Heading</h1>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"></button>
            </div>
            <div className="offcanvas-body d-block">
                <ul className="list-group">
                {
                    posts.map(
                        post => <MobileBlogNavbarButton
                            setPost={setPost}
                            postId={post?.postId}
                            title={post?.title}
                            key={keyIndex++}
                            isActive={(targetPost+1) == post?.postId}
                        />
                    )
                }
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileBlogMenu);