// Import the functions you need from the SDKs you need
import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCN2Ta_TV1EawMcwhsZLVaeIJKtK9hXVOw",
  authDomain: "tutorat-d9760.firebaseapp.com",
  projectId: "tutorat-d9760",
  storageBucket: "tutorat-d9760.appspot.com",
  messagingSenderId: "1015268901114",
  appId: "1:1015268901114:web:86b0328ddedbd067a88943"
};

// Initialize Firebase
const app = (getApps().find(r => r.name === "excellenttutorat") === undefined) ?
    initializeApp(firebaseConfig, "Tutorat") :
    getApp("excellenttutorat");

export const auth = getAuth(app);

export const db = getFirestore(app);