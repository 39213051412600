import React from 'react'
import { connect } from 'react-redux'

export const ServiceButton = ({ title, data, img }) => {
    const onMouseOut = (target) => {
        target.style.backgroundColor = "#f1f1f1";
        target.style.boxShadow = "0px 0px 20px 1px #aaaaaa";
    };
    const onMouseOver = (target) => {
        target.style.backgroundColor = "#a2a2a2"
        target.style.boxShadow = "0px 0px 35px 5px #666666";
    };

    const showModal = () => {
        const modalTitle = document.getElementById("modalTitle");
        modalTitle.innerHTML = title;

        const modalContent = document.getElementById("modalContent");
        modalContent.innerHTML = data;
    }

    return (
        <div
            style={styles.chip}
            onMouseOut={(e) => onMouseOut(e.target)}
            onMouseOver={(e) => onMouseOver(e.target)}
            data-bs-target="#myModal"
            data-bs-toggle="modal"
            onClick={() => showModal()}
        >
            <img
                src={img}
                alt="Person"
                width="96"
                height="96"
                style={styles.chipImg}
                onMouseOut={(e) => onMouseOut(e.target.parentElement)}
                onMouseOver={(e) => onMouseOver(e.target.parentElement)}
            />
            {title}
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

const styles = {
    chip: {
        display: "inline-block",
        padding: "0 35px",
        height: "50px",
        fontSize: "15px",
        lineHeight: "50px",
        borderRadius: "25px",
        backgroundColor: "#f1f1f1",
        marginLeft: "40px",
        marginRight: "40px",
        marginBotton: "20px",
        marginTop: "20px",
        boxShadow: "2px 2px 20px 1px #aaaaaa"
    },
    chipImg: {
        float: "left",
        margin: "0 10px 0 -25px",
        height: "50px",
        width: "50px",
        borderRadius: "50%"
    },
    disableSelect: {
        userSelect: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none"
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceButton);