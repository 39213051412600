import React, { useEffect } from 'react'
import { connect } from 'react-redux'

export const Welcome = () => {
    useEffect(() => {
        const x = setInterval(() => {
            const sectionIndex = window.fullpage_api.getActiveSection().index();
            if (sectionIndex !== 0) {
                return;
            }

            window.fullpage_api.moveSlideRight();
        }, 3000)

        return () => clearInterval(x);
    })
    

    return (
        <div id="section1" className="section active">
            <div className="slide" id="acceuil1"
                style={{
                    backgroundImage: "url(/img/acceuil1.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "80%",
                }}
            >
                <div className="text-dark d-flex justify-content-center">
                    <div
                        className="d-flex justify-content-center align-items-center text-light"
                        style={styles.messageContainer}
                    >
                        <h1><b>Excellent Tutorat fait du soutien aux éLèves</b></h1>
                    </div>
                </div>
            </div>
            <div className="slide" id="acceuil2"
                style={{
                    backgroundImage: "url(/img/acceuil2.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "80%",
                }}
            >
                <div className="text-dark d-flex justify-content-center">
                    <div
                        className="d-flex justify-content-center align-items-center text-light"
                        style={styles.messageContainer}
                    >
                        <h1><b>Excellent Tutorat accompagne vos enfants</b></h1>
                    </div>
                </div>
            </div>
            <div className="slide" id="acceuil3"
                style={{
                    backgroundImage: "url(/img/acceuil3.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "80%",
                }}
            >
                <div className="text-dark d-flex justify-content-center">
                    <div
                        className="d-flex justify-content-center align-items-center text-light"
                        style={styles.messageContainer}
                    >
                        <h1><b>Nous conduisons vos enfants à l’excellence</b></h1>
                    </div>
                </div>
            </div>
            <div className="slide" id="acceuil4"
                style={{
                    backgroundImage: "url(/img/acceuil4.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "80%",
                }}
            >
                <div className="text-dark d-flex justify-content-center">
                    <div
                        className="d-flex justify-content-center align-items-center text-light"
                        style={styles.messageContainer}
                    >
                        <h1><b>Nos enseignant(e)s qualifié(e)s sont à la disposition de vos enfants</b></h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

const styles = {
    messageContainer: {
        backgroundColor: "rgba(0, 0, 0, 1)",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 5,
        textAlign: "center",
        maxWidth: "90vw"
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);