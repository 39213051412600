import React from 'react'
import { connect } from 'react-redux'

export const MobileAbout = (props) => {
    return (
        <div id="section2" className="mb-1" style={{paddingTop: 55}}>
            <center>
                <h3><b>Qui Sommes Nous?</b></h3>
                <p className="px-4 py-2 mt-4 mb-4" style={{textAlign: "left"}}>
                    Excellence Tutorat est constitué de professionnels chevronnés d’éducation de l’Ontario et du Québec. Nous avons une équipe d’enseignantes et enseignants qui couvrent toutes les matières du primaire jusqu’au niveau collégial. Nous avons une équipe disponible à tout moment pour aider nos élèves et répondre à leurs défis.
                </p>
                <div className="card bg-success text-light" style={{...styles.textCard.default, marginBottom: 40}}>
                    <div className="card-body">
                        <h4 className="card-title"><i>En plus</i></h4>
                        <p className="card-text">
                            En plus, nous offrons des séances gratuites de conseils sur l’orientation et le cheminement scolaire aux parents d’élèves et aux élèves inscrits à nos programmes de tutorat.
                            Plus que des tuteurs, nos travailleurs sont des coachs d’accompagnement scolaire pour les familles.
                        </p>
                    </div>
                </div>
                <div className="container-fluid my-5">
                    <h4 style={{...styles.teachers, textAlign: "center", fontWeight: 800, paddingBottom: 20, marginBottom: 15}}><i>Nos enseignantes et enseignants sont:</i></h4>
                    <p style={styles.teachers}>
                        <b>Des membres de l’ordre des enseignantes et enseignants de l’Ontario:</b> mathématiques, physiques, chimie, biologie, français. anglais, sciences sociales (Histoire et géographie);
                    </p>
                    <p style={styles.teachers}>
                        <b>Des enseignants et enseignants ayant le brevet d’enseignement du ministère de l’éducation du Québec:</b> mathématiques, physiques, chimie, biologie, français. anglais, sciences sociales (Histoire et géographie)
                    </p>
                    <p style={styles.teachers}>
                        <b>Des enseignants et enseignants qualifiés en Français Langue Seconde;</b>
                    </p>
                    <p style={styles.teachers}>
                        <b>Des enseignants et enseignants qualifiés en Anglais Langue Seconde.</b>
                    </p>
                </div>
                <div className="container-fluid my-4">
                    <h4 style={{...styles.subjects, textAlign: "center", fontWeight: 800, paddingBottom: 20, marginBottom: 15}}><i>Nous enseignons:</i></h4>
                    <p style={styles.subjects}>
                        <b>Primaire 1ère à la 6e</b>
                    </p>
                    <p style={styles.subjects}>
                        <b>Secondaire 1 à secondaire 5 du Québec</b>
                    </p>
                    <p style={styles.subjects}>
                        <b>Élémentaire 7e et 8e Ontario</b>
                    </p>
                    <p style={styles.subjects}>
                        <b>Secondaire 9e à 12e Ontario</b>
                    </p>
                    <p style={styles.subjects}>
                        <b>CÉGEP</b>
                    </p>
                    <p style={styles.subjects}>
                        <b>Examen du Baccalauréat International</b>
                    </p>
                    <p style={styles.subjects}>
                        <b>Cours de français ou anglais comme langue seconde.</b>
                    </p>
                </div>
            </center>
        </div>
    );
};

const styles = {
    teachers: {
        width: "90vw",
        backgroundColor: "#f9dc94",
        textAlign: "left",
        padding: 15
    },
    subjects: {
        width: "90vw",
        backgroundColor: "#437fbe",
        textAlign: "left",
        padding: 15,
        color: "#ffffff"
    },
    row: {
        padedLeft: {
            width: "100vw",
            justifyContent: "start",
            paddingLeft: 75,
            paddingTop: 30
        },
        paddedRight: {
            width: "100vw",
            justifyContent: "end",
            paddingRight: 75
        },
        paddedEven: {
            wdith: "100vw",
            justifyContent: "center",
            marginTop: 50,
            paddingRight: 50,
            paddingLeft: 50
        }
    },
    textCard: {
        default: {
            width: "95vw",
            borderRadius: 20,
            padding: 10,
            textAlign: "left",
            noWidth: {
                borderRadius: 20,
                padding: 10,
                marginLeft: 20,
                marginRight: 20,
                height: 600
            }
        },
        listCard: {
            wdith: "25vw",
            padding: 10
        }
    },
    teacherLogo: {
        wdith: "50px"
    }
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileAbout);