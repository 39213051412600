import { Client } from '@microsoft/microsoft-graph-client'
import { TokenCredentialAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/azureTokenCredentials'
import { ClientSecretCredential } from '@azure/identity'
import { Providers } from '@microsoft/mgt-element'
import { Msal2Provider } from '@microsoft/mgt-msal2-provider'

Providers.globalProvider = new Msal2Provider({
    clientId: '52f6cbc2-b1f2-4701-8aaf-38ede53bca77'
})

const credential = new ClientSecretCredential(
    "d6ed9d5d-7e04-4743-94c2-5f0fbf6d694c",
    "52f6cbc2-b1f2-4701-8aaf-38ede53bca77",
    "P~28Q~NII86tc2exHduTol8dkQushHUrHCAXlaI3",
);

export const authProvider = new TokenCredentialAuthenticationProvider(
    credential, {
        scopes: ["https://graph.microsoft.com/.default"]
    }
)

export const client = Client.initWithMiddleware({
    debugLogging: true,
    authProvider: authProvider
});