import { BLOG_DATA_LOADED, LOADING_END, LOADING_START, SITE_META_LOADED, USER_SIGN_IN, USER_SIGN_OUT } from "./actions";

const initialState = {
    isLoading: false,
    user: {}
};

export const todos = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOADING_END:
            return {
                ...state,
                isLoading: false
            };

        case LOADING_START:
            return {
                ...state,
                isLoading: true
            };

        case USER_SIGN_IN: {
            const { user } = payload;
            return {
                ...state,
                user: user
            };
        };

        case USER_SIGN_OUT: {
            return {
                ...state,
                user: {}
            };
        };

        case BLOG_DATA_LOADED: {
            const { data } = payload;
            return {
                ...state,
                blogPosts: data
            };
        };

        case SITE_META_LOADED: {
            const { data } = payload;
            return {
                ...state,
                siteData: data
            };
        };

        default: return state;
    }
};