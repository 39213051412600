import React from 'react'
import { connect } from 'react-redux'

export const Map = (props) => {
    return (
        <div className="mapouter" style={styles.mapOuter}>
            <div className="gmap_canvas" style={styles.gmapCanvas}>
                <iframe
                    width="500"
                    height="350"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);

const styles = {
    mapOuter: {
        position: "relative",
        textAlign: "right",
        height: "60vh",
        width: "40vw"
    },
    gmapCanvas: {
        overflow: "hidden",
        background: "none!important",
        height: "60vh",
        width: "40vw"
    }
}