import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { loginUser, registerUser } from '../redux/thunks';

export const RegisterModal = ({ registerThunk, preferedService }) => {
    const email_validator = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [parentName, setParentName] = useState("");
    const [type, setType] = useState("");
    const [service, setService] = useState(preferedService);
    const [registrationError, setRegistrationError] = useState("");
    
    useEffect(() => {
        setService(preferedService);
    }, [preferedService])

    const hideModal = (e) => {
        const modal = document.getElementById("registerModal");

        if (e.target === modal) {
            modal.style.display = "none";
        }
    }

    const resetFields = () => {
        setEmail("");
        setPassword("");
        setFirstName("");
        setLastName("");
        setParentName("");
        setType("");
        setService(preferedService);
        setRegistrationError("")
    };

    const register = () => {
        setRegistrationError("");
        const registerData = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            parentName: (type === 'Élève') ?
                parentName : null,
            type: type,
            service: service
        }

        registerThunk(registerData, resetFields, setRegistrationError);
    }

    const validatePassword = () => {
        if (password === "") return false;
        else return true; 
    }
    const validateFirstName = () => {
        if (firstName === "") return false;
        else return true; 
    }
    const validateLastName = () => {
        if (firstName === "") return false;
        else return true; 
    }
    const validateParentName = () => {
        if (type !== "Élève") return true;

        if (parentName === "") return false;
        else return true; 
    }
    const validateService = () => {
        if (type === "") return false;
        else return true; 
    }
    const validateType = () => {
        if (service === "") return false;
        else return true; 
    }

    const canRegister = () => {if (
        !(email_validator.test(email))
    ) {
        return false;
    }

    return (
        validatePassword() &&
        validateFirstName() &&
        validateLastName() &&
        validateParentName() &&
        validateService() &&
        validateType()
    )
}

    return (
        <div id="registerModal" className='modal fade scrollingDiv'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <span style={{
                            textAlign: "center",
                            width: "90%",
                            color: "red",
                            fontWeight: "bold",
                        }}>
                            <small>{registrationError}</small>
                        </span>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => resetFields()}></button>
                    </div>
                    <div className='modal-body' id="modalContent">
                        <div className='d-grid'>
                            <div className="form-floating my-2 mx-1">
                                <input
                                    type="firstName"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="Nom"
                                    name="firstName"
                                    style={{
                                        opacity: 0.8
                                    }}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <label htmlFor="email">Nom</label>
                            </div>
                            <div className="form-floating my-2 mx-1">
                                <input
                                    type="lastName"
                                    className="form-control"
                                    id="lastName"
                                    placeholder="Prénom"
                                    name="lastName"
                                    style={{
                                        opacity: 0.8
                                    }}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                <label htmlFor="email">Prénom</label>
                            </div>
                            <div className="form-floating my-2 mx-1">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    name="email"
                                    style={{
                                        opacity: 0.8
                                    }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label htmlFor="email">Addresse Email</label>
                            </div>
                            <div className="form-floating my-2 mx-1">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Mot de passe"
                                    name="password"
                                    style={{
                                        opacity: 0.8
                                    }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <label htmlFor="password">Mot de passe</label>
                            </div>
                            <div className="form-floating">
                                <select className='form-select' id="type" name="type" value={type} onChange={(e) => setType(e.target.value)}>
                                    <option></option>
                                    <option>Parent</option>
                                    <option>Élève</option>
                                    <option>Instituteur</option>
                                </select>
                                <label htmlFor="type">Créer un compte d(e):</label>
                            </div>
                            <div className="form-floating my-2">
                                <select className='form-select' id="service" name="service" value={service} onChange={(e) => setService(e.target.value)}>
                                    <option></option>
                                    <option value="Préparation aux examens du Baccalauréat International">Préparation aux examens du Baccalauréat International</option>
                                    <option value="Soutiens scolaires individuels">Soutiens scolaires individuels</option>
                                    <option value="Cours privé de français et d’anglais">Cours privé de français et d’anglais</option>
                                    <option value="Service gratuit de conseil en Orientation">Service gratuit de conseil en Orientation</option>
                                    <option value="Blog">Blog</option>
                                </select>
                                <label htmlFor="service">Service d'interet</label>
                            </div>
                            {(type === "Élève") && (
                                <div className="form-floating my-2 mx-1">
                                    <input
                                        type="parentName"
                                        className="form-control"
                                        id="parentName"
                                        placeholder="Nom du parent"
                                        name="parentName"
                                        style={{
                                            opacity: 0.8
                                        }}
                                        value={parentName}
                                        onChange={(e) => setParentName(e.target.value)}
                                    />
                                    <label htmlFor="email">Nom du parent</label>
                                </div>
                            )}
                            <button
                                className={canRegister() ?
                                    'btn btn-warning btn-block my-2 p-2' : 'btn btn-secondary btn-block my-2 p-2 disabled'
                                }
                                onClick={() => register()}
                            >
                                S'enregistrer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    registerThunk: (data, resetFieldsFunc, errorUpdateFunc) => dispatch(registerUser(data, resetFieldsFunc, errorUpdateFunc))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);