import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getBlogPostCount } from "../redux/selectors";
import MEDitor from '@uiw/react-md-editor';
import { Converter } from 'showdown'
import { createPost } from "../redux/thunks";

export const BlogPostModal = ({ totalPostCount, newPostThunk, postToEdit=null }) => {
    const [title, setTitle] = useState((postToEdit === null) ? "" : postToEdit?.title);
    const [content, setContent] = useState((postToEdit === null) ? "" : postToEdit?.content);

    const converter = new Converter();

    const resetFields = () => {
        setTitle("");
        setContent("");
    }

    const canSubmit = () => {
        return (
            title?.length > 0 && content?.length > 0
        )
    }

    const submit = () => {
        const postData = {
            docName: `post${totalPostCount+1}`,
            data: {
                title: title,
                author: "Aboubacar Sanogo",
                date: new Date().toISOString().split("T")?.at(0),
                content: converter.makeHtml(content),
                postId: totalPostCount+1
            }
        }

        newPostThunk(postData, resetFields)
    }

    return (
        <div className="modal fade" id="blogModal">
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <span style={{
                            textAlign: "center",
                            width: "90%",
                            fontWeight: "bold",
                        }}>
                            Nouvel article 
                        </span>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating my-2 mx-1">
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                placeholder="Titre"
                                name="title"
                                style={{
                                    opacity: 0.8
                                }}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <label htmlFor="email">Titre</label>
                        </div>
                        <button className="my-2 btn btn-light disabled">Contenu:</button>
                        <button
                            className="btn btn-dark"
                            style={{float: "right"}}
                            onClick={() => resetFields()}
                        >
                            Effacer
                        </button>
                        <MEDitor
                            height="50vh"
                            value={content}
                            onChange={setContent}
                            tabSize={4}
                            visibleDragbar={false}
                            textareaProps={{
                                id: "blogEditorContent",
                                
                            }}
                        />
                        <div className="d-grid mt-3">
                            <button
                                className={(canSubmit()) ?
                                    "my-2 btn btn-primary btn-block" :
                                    "my-2 btn btn-secondary btn-block disabled"
                                }
                                onClick={() => submit()}
                            >
                                Soumettre
                            </button>
                        </div>
                    </div>
                    {/* <div className="modal-footer"></div> */}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    totalPostCount: getBlogPostCount(state)
});

const mapDispatchToProps = (dispatch) => ({
    newPostThunk: (postData, resetFieldsFunc) => dispatch(createPost(postData, resetFieldsFunc))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogPostModal);