import React from 'react'
import { connect } from 'react-redux'
import ContactForm from "../../components/ContactForm";
import { getSiteData } from "../../redux/selectors";

export const MobileContact = ({ siteData }) => {
    return (
        <div id="section4" className="my-5 py-5">
            <center><h3><b>Contactez Nous</b></h3></center>
            <ContactForm />
            <div className='m-3 py-1 d-flex align-items-center justify-content-center'>
                <i className='fa fa-phone me-3' style={{fontSize: 32}}></i>
                <b>{siteData?.phoneNumber}</b>
            </div>
            <div className='m-3 d-flex align-items-center justify-content-center'>
                <i className='fa fa-envelope-open me-3' style={{fontSize: 32}}></i>
                <b>
                    <a
                        href={`mailto:${siteData?.email}`}
                        className="text-dark"
                        style={{
                            textDecoration: "none"
                        }}
                    >
                        {siteData?.email}
                    </a>
                </b>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    siteData: getSiteData(state)
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileContact);