import React from 'react'
import { connect } from 'react-redux'
import { auth } from '../firebase';

export const Modal = ({ title, data, setPreferedService, preferedService }) => {
    const moveToRegistration = (e) => {
        const modal = document.getElementById("myModal");

        if (e.target === modal) {
            modal.style.display = "none";
            const modalContent = document.getElementById("modalContent");
            modalContent.innerHTML = "";
        }
        setPreferedService(document.getElementById("modalTitle").innerText);
    }

    return (
        <div id="myModal" className='modal fade scrollingDiv'>
            <div className='modal-dialog modal-lg modal-dialog-scrollable'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <b><h4 className='modal-title' id="modalTitle"></h4></b>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className='modal-body' id="modalContent"></div>
                    <div className='modal-footer'>
                        <a
                            id="register2"className='btn btn-warning text-light'
                            style={{
                                opacity: 0.75,
                                fontSize: 14,
                                width: 120,
                                display: (auth.currentUser === null) ? "" : "none"
                            }}
                            data-bs-target="#registerModal"
                            data-bs-toggle="modal"
                            onClick={(e) => moveToRegistration(e)}
                        >
                            <b>S'enregistrer</b>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);