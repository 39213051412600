import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { auth } from '../../firebase';
import { logoutUser } from '../../redux/thunks';

export const MobileNavbar = ({ logoutThunk }) => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const x = setInterval(() => {
            setLoggedIn(auth.currentUser !== null);
        });

        return () => clearInterval(x);
    })

    return (
        <nav id="mobileNavbar" className="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
            <div className="container-fluid">
                <span className="navbar-brand" href="#" style={{fontSize: 8}}>
                    <b>Excellent Tutorat</b>
                </span>
                <button
                    id="register"className='btn btn-warning text-light'
                    style={{
                        fontSize: 10,
                        width: 100,
                        display: (loggedIn === false) ? "" : "none"
                    }}
                    data-bs-target="#registerModal"
                    data-bs-toggle="modal"
                >
                    <b>S'enregistrer</b>
                </button>
                <button
                    id="login"
                    className='btn btn-light text-dark'
                    style={{
                        fontSize: 10,
                        width: 100,
                        display: (loggedIn === false) ? "" : "none"
                    }}
                    data-bs-target="#loginModal"
                    data-bs-toggle="modal"
                >
                    <b>Connexion</b>
                </button>
                <button
                    id="logout"className='btn btn-danger text-light me-5'
                    style={{
                        fontSize: 10,
                        width: 100,
                        display: (loggedIn === true) ? "" : "none"
                    }}
                    onClick={() => logoutThunk()}
                >
                    <b>Déconnexion</b>
                </button>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsibleNavbar"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="#section1">Accueil</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section2">Qui Sommes Nous</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section3">Nos Services</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section4">Contactez Nous</a>
                        </li>
                        {
                            (auth?.currentUser?.email === "shadow@root.org") &&
                            (
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog">Blog</a>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    logoutThunk: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavbar);