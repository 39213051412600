import React from 'react'
import { connect } from 'react-redux'

export const About = (props) => {
    return (
        <div id="section2" className="section">
            <div className="slide">
                <div className="text-dark d-flex justify-content-center py-5">
                    <div className="d-grid justify-content-center align-items-center" style={{overflowY: "scroll", overflowX: "hidden", marginTop: 100}}>
                        <div className="row" style={styles.row.paddedRight}>
                            <div className="card bg-primary text-light" style={styles.textCard.default}>
                                <div className="card-body">
                                    <h4 className="card-title"><i>Qui sommes nous?</i></h4>
                                    <p className="card-text">
                                        Excellence Tutorat est constitué de professionnels chevronnés d’éducation de l’Ontario et du Québec. Nous avons une équipe d’enseignantes et enseignants qui couvrent toutes les matières du primaire jusqu’au niveau collégial. Nous avons une équipe disponible à tout moment pour aider nos élèves et répondre à leurs défis.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={styles.row.padedLeft}>
                            <div className="card bg-success text-light" style={styles.textCard.default}>
                                <div className="card-body">
                                    <h4 className="card-title"><i>En plus</i></h4>
                                    <p className="card-text">
                                        En plus, nous offrons des séances gratuites de conseils sur l’orientation et le cheminement scolaire aux parents d’élèves et aux élèves inscrits à nos programmes de tutorat.
                                        Plus que des tuteurs, nos travailleurs sont des coachs d’accompagnement scolaire pour les familles.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className='col-sm-6' style={{textAlign: "center"}}>
                                <div className="container-fluid my-5 px-5">
                                    <h4 style={{...styles.teachers, textAlign: "center", fontWeight: 800, paddingBottom: 20, marginBottom: 15}}><i>Nos enseignantes et enseignants sont:</i></h4>
                                    <p style={styles.teachers}>
                                        <b>Des membres de l’ordre des enseignantes et enseignants de l’Ontario:</b> mathématiques, physiques, chimie, biologie, français. anglais, sciences sociales (Histoire et géographie);
                                    </p>
                                    <p style={styles.teachers}>
                                        <b>Des enseignantes et enseignants ayant le brevet d’enseignement du ministère de l’éducation du Québec:</b> mathématiques, physiques, chimie, biologie, français. anglais, sciences sociales (Histoire et géographie)
                                    </p>
                                    <p style={styles.teachers}>
                                        <b>Des enseignantes et enseignants qualifiés en Français Langue Seconde;</b>
                                    </p>
                                    <p style={styles.teachers}>
                                        <b>Des enseignantes et enseignants qualifiés en Anglais Langue Seconde.</b>
                                    </p>
                                </div>
                            </div>
                            <div className='col-sm-6' style={{textAlign: "center"}}>
                                <div className="container-fluid my-5 px-5">
                                    <h4 style={{...styles.subjects, textAlign: "center", fontWeight: 800, paddingBottom: 20, marginBottom: 15}}><i>Nous enseignons:</i></h4>
                                    <p style={styles.subjects}>
                                        <b>Primaire 1ère à la 6e</b>
                                    </p>
                                    <p style={styles.subjects}>
                                        <b>Secondaire 1 à secondaire 5 du Québec</b>
                                    </p>
                                    <p style={styles.subjects}>
                                        <b>Élémentaire 7e et 8e Ontario</b>
                                    </p>
                                    <p style={styles.subjects}>
                                        <b>Secondaire 9e à 12e Ontario</b>
                                    </p>
                                    <p style={styles.subjects}>
                                        <b>CÉGEP</b>
                                    </p>
                                    <p style={styles.subjects}>
                                        <b>Examen du Baccalauréat International</b>
                                    </p>
                                    <p style={styles.subjects}>
                                        <b>Cours de français ou anglais comme langue seconde.</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

const styles = {
    teachers: {
        width: "100%",
        backgroundColor: "#f9dc94",
        textAlign: "left",
        padding: 15
    },
    subjects: {
        width: "100%",
        backgroundColor: "#437fbe",
        textAlign: "left",
        padding: 15,
        color: "#ffffff"
    },
    row: {
        padedLeft: {
            width: "100vw",
            justifyContent: "start",
            paddingLeft: 75,
            paddingTop: 30
        },
        paddedRight: {
            width: "100vw",
            justifyContent: "end",
            paddingRight: 75
        },
        paddedEven: {
            wdith: "100vw",
            justifyContent: "center",
            marginTop: 50,
            paddingRight: 50,
            paddingLeft: 50
        }
    },
    textCard: {
        default: {
            width: "55vw",
            borderRadius: 20,
            padding: 10,
            noWidth: {
                borderRadius: 20,
                padding: 10,
                marginLeft: 20,
                marginRight: 20,
                height: 600
            }
        },
        listCard: {
            wdith: "25vw",
            padding: 10
        }
    },
    teacherLogo: {
        wdith: "50px"
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);