import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getBlogData } from '../redux/selectors';
import MobileBlogContent from './components/MobileBlogContent';
import MobileBlogMenu from './components/MobileBlogMenu';

export const MobileBlog = ({ setActivePage, posts }) => {
    useEffect(() => {
        setActivePage("blog");
    })
    const [targetPost, setTargetPost] = useState(0);

    return (
        <div className='bg-light'>
            {/* <div style={styles.floatingButtonBL}>+</div> */}
            <MobileBlogMenu posts={posts} setPost={setTargetPost} targetPost={targetPost} setActivePage={setActivePage}/>
            <button className="bg-dark text-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebar" style={styles.floatingButtonBL}><i className="fa fa-reorder" style={{fontSize: 24}}></i></button>
            <a href='/' className='bg-dark text-light' style={styles.floatingButtonBR}>
                <i className="fa fa-home" style={{fontSize: 32}}></i>
            </a>
            <MobileBlogContent post={posts[targetPost]}/>
        </div>
    );
};

const styles = {
    floatingButtonBL: {
        zIndex: 5,
        position: "fixed",
        bottom: 20,
        left: 20,
        cursor: "pointer",
        borderRadius: 50,
        width: 60,
        height: 60,
        boxShadow: "0px 0px 8px 1px #444",
        border: "none",
        fontWeight: "bold",
        opacity: 0.9
    },
    floatingButtonBR: {
        zIndex: 5,
        position: "fixed",
        bottom: 20,
        right: 20,
        cursor: "pointer",
        borderRadius: 50,
        width: 60,
        height: 60,
        boxShadow: "0px 0px 8px 1px #444",
        border: "none",
        fontWeight: "bold",
        opacity: 0.9,
        textAlign: "center",
        lineHeight: "65px"
    }
}

const mapStateToProps = (state) => ({
    posts: getBlogData(state)
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileBlog);