import React from 'react'
import { connect } from 'react-redux'

export const MobileBlogContent = ({ post }) => {
    return (
        <div className="slide" style={{width: "inherit", height: "100vh"}}>
            <div className="container-fluid d-block" style={styles.container}>
                <div className="my-3 d-flex align-items-center justify-content-center" style={{...styles.lightBox, height: "10%", borderRadius: 5}}>
                    <h3><b>{post?.title}</b></h3>
                </div>
                <div className="my-3 d-flex align-items-center justify-content-center" style={{...styles.lightBox, height: "5%", borderRadius: 5}}>
                    <b>{post?.author} -&nbsp;</b><i>2022-09-09</i>
                </div>
                <div className="mt-3 d-flex p-5 scrollingDiv" style={{...styles.lightBox, height: "75%", textAlign: "left", overflowY: "scroll", borderRadius: 5}}>
                    {post?.content}
                    <br/><br/>&nbsp;
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: "90vw",
        height: "90vh",
        textAlign: "center",
        marginTop: 50
    },
    lightBox: {
        boxShadow: "0px 0px 7px 3px #ddd",
        width: "100%"
    },
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileBlogContent);