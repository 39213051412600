import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import MobileAbout from "./components/MobileAbout";
import MobileContact from "./components/MobileContact";
import MobileNavbar from "./components/MobileNavbar";
import MobileServices from "./components/MobileServices";
import MobileWelcome from "./components/MobileWelcome";

export const MobileHome = ({ setActivePage }) => {
    useEffect(() => {
        setActivePage("home");
    })

    return (
        <div
            data-bs-spy="scroll"
            data-bs-target=".navbar"
            data-bs-offset="100"
            className="d-block"
            style={{
                height: "100vh",
                width: "100vw",
                paddingTop: 55
            }}
        >
            <img src="/img/logo.png" width="100px" style={styles.logo}/>
            <MobileNavbar />
            <MobileWelcome />
            <MobileAbout />
            <MobileServices />
            <MobileContact />
        </div>
    );
};

const styles = {
    logo: {
        bottom: 30,
        right: 30,
        position: "fixed",
        zIndex: 52
    }
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHome);