import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BlogContent from "../components/BlogContent";
import BlogNavbar from "../components/BlogNavbar";
import { getBlogData } from "../redux/selectors";
import anime from 'animejs/lib/anime.es'
import BlogNavbarButton from "../components/BlogNavbarButton";
import { auth } from "../firebase";
import { logoutUser } from "../redux/thunks";

export const Blog = ({api, posts, setActivePage, logoutThunk }) => {
    useEffect(() => {
        setActivePage("blog");
    })

    useEffect(() => {
        const x = setInterval(() => {
            setLoggedIn(auth.currentUser !== null);
        });

        return () => clearInterval(x);
    })

    const [targetPost, setTargetPost] = useState(0);
    const [search, setSearch] = useState("")
    const [watchingContent, setWatchingContent] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false);

    // anime({
    //     targets: "#blogTitle",
    //     opacity: [0, 1],
    //     duration: 2500,
    //     loop: false
    // })

    // anime({
    //     targets: "#postSearch",
    //     height: [0, "calc(3.5rem + 2px)"],
    //     opacity: [0, 1],
    //     color: ["rgba(0, 0, 0, 0)", "rgba(255, 255, 255, 1)"],
    //     duration: 375,
    //     delay: 800,
    //     loop: false,
    //     easing: "linear"
    // })

    // anime({
    //     targets: "#postList",
    //     height: [0, "50vh"],
    //     opacity: [0, 1],
    //     margin: [0, "30px 10px"],
    //     padding: [0, "30px"],
    //     duration: 1250,
    //     delay: 1200,
    //     loop: false,
    //     easing: "linear"
    // })

    const filterPosts = () => {
        return posts.filter(
            p => p?.title.toLowerCase().includes(search.toLowerCase())
        );
    }

    const displayPostContents = (postId) => {
        setTargetPost(postId-1)

        const target = document.getElementById("blogContent")
        target.style.display = "";

        anime({
            targets: "#blogMenu",
            opacity: [1, 0.05],
            duration: 250,
            loop: false,
            easing: "linear",
        })

        anime({
            targets: "#blogContent",
            opacity: [0, 1],
            width: ["0vw", "90vw"],
            height: ["0vh", "90vh"],
            opacity: [0, 1],
            delay: 250,
            duration: 250,
            loop: false,
            easing: "linear",
        })

        anime({
            targets: ["#postTitle", "#postDate", "#postAuthor", "#postText"],
            opacity: [0, 1],
            duration: 250,
            delay: 500,
            loop: false,
            easing: "linear",
        })

        setWatchingContent(true)
    }

    const hidePostContents = () => {
        anime({
            targets: ["#postTitle", "#postDate", "#postAuthor", "#postText"],
            opacity: [1, 0],
            duration: 250,
            loop: false,
            easing: "linear",
        })

        anime({
            targets: "#blogMenu",
            opacity: [0.05, 1],
            duration: 250,
            delay: 375,
            loop: false,
            easing: "linear",
        })

        anime({
            targets: "#blogContent",
            width: ["90vw", "0vw"],
            height: ["90vh", "0vh"],
            opacity: [1, 0],
            duration: 250,
            delay: 250,
            loop: false,
            easing: "linear",
            complete: () => {
                const target = document.getElementById("blogContent")
                target.style.display = "none";
            }
        })


        setWatchingContent(false)
    }

    const animateButton = (e, props) => {
        console.log(props)
        return anime({
            targets: e.target.id,
            ...props
        })
    }

    return (
        <div
            id="section20"
            className="section text-light h-100"
            style={{
                backgroundImage: "url(/img/blog.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "80%"
            }}
            onClick={(e) => {
                if(!watchingContent) return
                if (e.target.id !== "blogContent") hidePostContents()
            }}
        >
            <div
                id="blogContent"
                className="p-4"
                style={{
                    position: "absolute",
                    zIndex: "100",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    width: "90vw",
                    height: "90vh",
                    top: "5%",
                    left: "5%",
                    opacity: 1,
                    display: "none"
                }}
            >
                <center id="postTitle"><h1><strong>{posts[targetPost]?.title}</strong></h1></center>
                <center id="postDate"><small>{posts[targetPost]?.date}</small></center>
                <div
                    id="postText"
                    className="my-3 d-flex p-5 scrollingDiv"
                    style={{
                        height: "75%",
                        overflowY: "scroll"
                    }}
                    dangerouslySetInnerHTML={{
                        __html: 
                            posts[targetPost]?.content + "<br/><br/>&nbsp;"
                    }}
                ></div>
                <span id="postAuthor" style={{float: "right", fontWeight: "bold"}}><small>{posts[targetPost]?.author}</small></span>
            </div>
            <div
                id="blogMenu"
                className="d-flex align-items-center justify-content-center h-100"
            >
                <div className="d-block">
                    <div id="blogTitle" className="d-flex justify-content-center" style={{textAlign: "center", width: "inherit"}}> 
                        <h1><strong>Excellent Tutorat</strong></h1>
                        <p className="ms-2"><strong>[BLOG]</strong></p>
                    </div>
                    <div
                        id="postSearch"
                        className="form-floating my-2 mx-1"
                        style={{
                            borderRadius: 20
                        }}
                    >
                        <input
                            type="text"
                            className="form-control"
                            id="search"
                            placeholder="Cherchez un article"
                            name="search"
                            style={{
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                                border: "none",
                                width: "80vw",
                                color: "#fff"
                            }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onBlur={(e) => e.target.style.backgroundColor = "rgba(255, 255, 255, 0.1)"}
                            onMouseLeave={(e) => {
                                if (document.activeElement !== e.target) {
                                    e.target.style.backgroundColor = "rgba(255, 255, 255, 0.1)"
                                }
                            }}
                            onFocus={(e) => e.target.style.backgroundColor = "rgba(255, 255, 255, 0.2)"}
                            onMouseEnter={(e) => e.target.style.backgroundColor = "rgba(255, 255, 255, 0.2)"}
                        />
                        <label htmlFor="email">Cherchez un article</label>
                    </div>
                    <div
                        id="postList"
                        className="d-block my-3 mx-1 p-3"
                        style={{
                            width: "80vw",
                            height: "70vh",
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            overflowY: "scroll",
                            borderRadius: 20
                        }}
                    >
                    <center>
                        {
                            filterPosts().map(p => <BlogNavbarButton
                                post={p}
                                setPost={setTargetPost}
                                key={p?.postId}
                                displayPost={displayPostContents}
                            />)
                        }
                    </center>
                    </div>
                    <div
                        id="homeButton"
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            position: "absolute",
                            top: "5%",
                            left: "3%",
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            fontWeight: "bolder",
                            backgroundColor: "rgba(200, 200, 200, 0.3)",
                            zIndex: 1000,
                            cursor: "pointer"
                        }}
                        onMouseEnter={(e) => {
                            anime({
                                targets: "#homeButton",
                                backgroundColor: [
                                    "rgba(255, 255, 255, 0.8)"
                                ],
                                duration: 250,
                                loop: false,
                                easing: "linear",
                            })
                            anime({
                                targets: "#homeLink",
                                color: ["#fff", "#000"],
                                duration: 125,
                                loop: false,
                                easing: "linear",
                            })
                        }}
                        onMouseLeave={(e) => {
                            anime({
                                targets: "#homeButton",
                                backgroundColor: [
                                    "rgba(200, 200, 200, 0.3)"
                                ],
                                duration: 250,
                                loop: false,
                                easing: "linear",
                            })
                            anime({
                                targets: "#homeLink",
                                color: ["#000", "#fff"],
                                duration: 125,
                                loop: false,
                                easing: "linear",
                            })
                        }}
                    >
                        <a id="homeLink" className="btn" style={{color: "#fff"}}  href="/">
                            <i className="fa fa-home" style={{fontSize: 28}}/>
                        </a>
                    </div>
                    <div
                        id="loginButton"
                        className="align-items-center justify-content-center"
                        style={{
                            position: "absolute",
                            top: "15%",
                            left: "3%",
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            fontWeight: "bolder",
                            backgroundColor: "rgba(200, 200, 200, 0.3)",
                            zIndex: 1000,
                            cursor: "pointer",
                            display: (auth.currentUser === null) ? "flex" : "none"
                        }}
                        onMouseEnter={(e) => {
                            anime({
                                targets: "#loginButton",
                                backgroundColor: [
                                    "rgba(255, 255, 255, 0.8)"
                                ],
                                duration: 250,
                                loop: false,
                                easing: "linear",
                            })
                            anime({
                                targets: "#loginText",
                                color: ["#fff", "#000"],
                                duration: 125,
                                loop: false,
                                easing: "linear",
                            })
                        }}
                        onMouseLeave={(e) => {
                            anime({
                                targets: "#loginButton",
                                backgroundColor: [
                                    "rgba(200, 200, 200, 0.3)"
                                ],
                                duration: 250,
                                loop: false,
                                easing: "linear",
                            })
                            anime({
                                targets: "#loginText",
                                color: ["#000", "#fff"],
                                duration: 125,
                                loop: false,
                                easing: "linear",
                            })
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#loginModal"
                    >
                        <i id="loginText" className="fa fa-sign-in" style={{fontSize: 28}}/>
                    </div>
                    <div
                        id="registerButton"
                        className="align-items-center justify-content-center"
                        style={{
                            position: "absolute",
                            top: "25%",
                            left: "3%",
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            fontWeight: "bolder",
                            backgroundColor: "rgba(200, 200, 200, 0.3)",
                            zIndex: 1000,
                            cursor: "pointer",
                            display: (auth.currentUser === null) ? "flex" : "none"
                        }}
                        onMouseEnter={(e) => {
                            anime({
                                targets: "#registerButton",
                                backgroundColor: [
                                    "rgba(255, 255, 255, 0.8)"
                                ],
                                duration: 250,
                                loop: false,
                                easing: "linear",
                            })
                            anime({
                                targets: "#registerText",
                                color: ["#fff", "#000"],
                                duration: 125,
                                loop: false,
                                easing: "linear",
                            })
                        }}
                        onMouseLeave={(e) => {
                            anime({
                                targets: "#registerButton",
                                backgroundColor: [
                                    "rgba(200, 200, 200, 0.3)"
                                ],
                                duration: 250,
                                loop: false,
                                easing: "linear",
                            })
                            anime({
                                targets: "#registerText",
                                color: ["#000", "#fff"],
                                duration: 125,
                                loop: false,
                                easing: "linear",
                            })
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#registerModal"
                    >
                        <i id="registerText" className="fa fa-user-plus" style={{fontSize: 28}}/>
                    </div>
                    <div
                        id="logoutButton"
                        className="align-items-center justify-content-center"
                        style={{
                            position: "absolute",
                            top: "15%",
                            left: "3%",
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            fontWeight: "bolder",
                            backgroundColor: "rgba(200, 200, 200, 0.3)",
                            zIndex: 1000,
                            cursor: "pointer",
                            display: (auth.currentUser === null) ? "none" : "flex"
                        }}
                        onMouseEnter={(e) => {
                            anime({
                                targets: "#logoutButton",
                                backgroundColor: [
                                    "rgba(255, 255, 255, 0.8)"
                                ],
                                duration: 250,
                                loop: false,
                                easing: "linear",
                            })
                            anime({
                                targets: "#logoutText",
                                color: ["#fff", "#000"],
                                duration: 125,
                                loop: false,
                                easing: "linear",
                            })
                        }}
                        onMouseLeave={(e) => {
                            anime({
                                targets: "#logoutButton",
                                backgroundColor: [
                                    "rgba(200, 200, 200, 0.3)"
                                ],
                                duration: 250,
                                loop: false,
                                easing: "linear",
                            })
                            anime({
                                targets: "#logoutText",
                                color: ["#000", "#fff"],
                                duration: 125,
                                loop: false,
                                easing: "linear",
                            })
                        }}
                        onClick={() => logoutThunk()}
                    >
                        <i id="logoutText" className="fa fa-sign-out" style={{fontSize: 28}}/>
                    </div>
                    {
                        (auth?.currentUser?.email === "root@shadow.org") &&
                        <div
                            id="newPostButton"
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                position: "absolute",
                                top: "5%",
                                right: "3%",
                                width: 50,
                                height: 50,
                                borderRadius: 50,
                                fontWeight: "bolder",
                                backgroundColor: "rgba(200, 200, 200, 0.3)",
                                zIndex: 1000,
                                cursor: "pointer"
                            }}
                            onMouseEnter={(e) => {
                                anime({
                                    targets: "#newPostButton",
                                    backgroundColor: [
                                        "rgba(255, 255, 255, 0.8)"
                                    ],
                                    duration: 250,
                                    loop: false,
                                    easing: "linear",
                                })
                                anime({
                                    targets: "#newPostIcon",
                                    color: ["#fff", "#000"],
                                    duration: 125,
                                    loop: false,
                                    easing: "linear",
                                })
                            }}
                            onMouseLeave={(e) => {
                                anime({
                                    targets: "#newPostButton",
                                    backgroundColor: [
                                        "rgba(200, 200, 200, 0.3)"
                                    ],
                                    duration: 250,
                                    loop: false,
                                    easing: "linear",
                                })
                                anime({
                                    targets: "#newPostIcon",
                                    color: ["#000", "#fff"],
                                    duration: 125,
                                    loop: false,
                                    easing: "linear",
                                })
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#blogModal"
                        >
                            <i id="newPostIcon" className="fa fa-plus-square" style={{fontSize: 28}}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    posts: getBlogData(state)
});

const mapDispatchToProps = (dispatch) => ({
    logoutThunk: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Blog);