import React from 'react'
import { connect } from 'react-redux'
import { auth } from '../../firebase';

export const MobileModal = ({ setPreferedService }) => {
    const moveToRegistration = (e) => {
        const modal = document.getElementById("myModal");

        if (e.target === modal) {
            modal.style.display = "none";
            const modalContent = document.getElementById("modalContent");
            modalContent.innerHTML = "";
        }
        setPreferedService(document.getElementById("modalTitle").innerText);
    }

    return (
        <div className="modal modal-xl" id="myModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="modalTitle"></h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body" id="modalContent"></div>
                    <div className='modal-footer'>
                        <a
                            id="register2"className='btn btn-warning text-light'
                            style={{
                                opacity: 0.75,
                                fontSize: 14,
                                width: 120,
                                display: (auth.currentUser === null) ? "" : "none"
                            }}
                            data-bs-target="#registerModal"
                            data-bs-toggle="modal"
                            onClick={(e) => moveToRegistration(e)}
                        >
                            <b>S'enregistrer</b>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileModal);