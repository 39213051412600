import React from 'react'
import { connect } from 'react-redux'

export const MobileBlogNavbarButton = ({ title, postId, setPost, isActive }) => {
    const styles = {
        manuLink: {
            height: 50,
            width: "100%",
            borderRadius: 5,
            marginTop: 15,
            marginBotton: 15,
            boxShadow: 
                (isActive) ?
                    "0px 0px 7px 2px #aaa" : "0px 0px 7px 1px #444",
            overflow: "clip"
        }
    }

    return (
        <li className="nav-item" style={{width: "inherit"}}>
            <button
                className={(isActive) ? 
                    "nav-link btn btn-dark text-light active" :
                    "nav-link btn btn-dark text-light"
                }
                style={styles.manuLink}
                href="#"
                onClick={() => setPost(postId-1)}
                data-bs-toggle="offcanvas"
                data-bs-target="#sidebar"
            >
                {/* <div className="row">
                    <div className="col-sm-9">
                        {title}
                    </div>
                    <div className="col-sm-3">
                        <button className="badge btn btn-info" onClick={() => alert("edit")}>
                            <i className="fa fa-pencil"></i>
                        </button>
                        <button className="badge btn btn-danger" onClick={() => alert("delete")}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                </div> */}
                {title}
            </button>
        </li>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileBlogNavbarButton);