import { HomePage } from './pages/HomePage'
import { Route, Routes } from 'react-router'
import ReactFullpage from '@fullpage/react-fullpage';
import Modal from "./components/Modal";
import LoginModal from './components/LoginModal';
import RegisterModal from './components/RegisterModal';
import Blog from "./pages/Blog";
import { connect } from 'react-redux'
import { loadData } from "./redux/thunks";
import { useState } from "react";
import Navbar from "./components/Navbar";
import { BrowserView, MobileView } from 'react-device-detect';
import MobileHome from "./pages_mobile/MobileHome";
import MobileModal from './pages_mobile/components/MobileModal';
import MobileBlog from './pages_mobile/MobileBlog';
import BlogPostModal from "./components/BlogPostModal";
import { auth } from './firebase';


const App = ({ loadBlogDataThunk }) => {
	loadBlogDataThunk();
	const [preferedService, setPreferedService] = useState("");
	const [activePage, setActivePage] = useState("home");

	return (
		<div className="App" style={{
			width: "100vw",
			height: "100vh"
		}}>
			<BrowserView>
				{(activePage !== "blog" && <Navbar />)}
				<Modal setPreferedService={setPreferedService} preferedService={preferedService}/>
				<LoginModal />
				<RegisterModal preferedService={preferedService}/>
				<BlogPostModal />
				<ReactFullpage
					licenseKey="HF2KK-N0KSK-VHK27-I404H-LQJPN"
					controlArrows={false}
					scrollingSpeed={750}
					// continuousVertical={true}
					continuousHorizontal={true}
					// navigation={true}
					// navigationTooltips={
					// 	["Acceuil", "Qui Sommes Nous?", "Nos Services", "Contactez Nous"]
					// }
					slidesNavigation={true}
					normalScrollElements=".scrollingDiv"
					// showActiveTooltip
					fixedElements="#header, #footer"
					menu="#menu"
					parallax={true}
					parallaxOptions={{
						percentage: 62,
						property: "translate",
						type: "reveal"
					}}
					render={
						({state, fullpageApi}) => {
							return (
								<ReactFullpage.Wrapper>
									<Routes>
										<Route path="/" element={<HomePage api={fullpageApi} setActivePage={setActivePage}/>} />
										{
											(auth?.currentUser?.email === "root@shadow.org") &&
											<Route path="/blog" element={<Blog api={fullpageApi} setActivePage={setActivePage}/>} />
										}
									</Routes>
								</ReactFullpage.Wrapper>
							)
						}
					}
				/>
			</BrowserView>
			<MobileView>
				<MobileModal setPreferedService={setPreferedService}/>
				<RegisterModal preferedService={preferedService} />
				<LoginModal />
				<Routes>
					<Route path="/" element={<MobileHome setActivePage={setActivePage}/>} />
					{
						(auth?.currentUser?.email === "root@shadow.org") &&
						<Route path="/blog" element={<MobileBlog setActivePage={setActivePage}/>} />
					}
				</Routes>
			</MobileView>
		</div>
	)
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    loadBlogDataThunk: (status) => dispatch(loadData(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);