import React from 'react'
import { connect } from 'react-redux'

export const BlogContent = ({ post }) => {
    return (
        <div className="slide" style={{width: "inherit", height: "100vh"}}>
            <div className="container-fluid d-block" style={styles.container}>
                <div className="my-3 d-flex align-items-center justify-content-center" style={{...styles.lightBox, height: "10%"}}>
                    <h3><b>{post?.title}</b></h3>
                </div>
                <div className="my-3 d-flex align-items-center justify-content-center" style={{...styles.lightBox, height: "5%"}}>
                    <b>{post?.author} -&nbsp;</b><i>2022-09-09</i>
                </div>
                <div
                    className="my-3 d-flex p-5 scrollingDiv"
                    style={{
                        ...styles.lightBox,
                        height: "75%",
                        textAlign: "left",
                        overflowY: "scroll"
                    }}
                    dangerouslySetInnerHTML={{
                        __html: 
                            post?.content + "<br/><br/>&nbsp;"
                    }}
                >
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: "70vw",
        height: "95vh",
        textAlign: "center"
    },
    lightBox: {
        boxShadow: "0px 0px 7px 3px #888",
        width: "100%"
    },
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogContent);