import React from 'react'
import { connect } from 'react-redux'
import { getUserData } from "../redux/selectors";
import BlogNavbarButton from "./BlogNavbarButton";

export const BlogNavbar = ({ posts, setPost, targetPost, isAdmin }) => {
    let keyIndex = 0;
    return (
        <nav className="navbar fixed-top pe-2" style={{
            width: "inherit",
            height: "100vh"
        }}>
            <div className="container-fluid d-block" style={{
                overflowX: "hidden",
                overflowY: "scroll",
                height: "inherit"
            }}>
                <a className="btn btn-light my-1" style={{
                    ...styles.homeButton, ...styles.manuLink
                }}
                    href="/"
                >
                    <i className="fa fa-arrow-circle-left" style={{fontSize: "32px"}}></i>
                    &nbsp;
                    &nbsp;
                    <b>Back</b>
                </a>
                {
                    (isAdmin) &&
                    (
                        <button
                            className="btn btn-primary mt-1 mb-4"
                            style={{
                                ...styles.homeButton, ...styles.manuLink
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#blogModal"
                        >
                            <i className="fa fa-plus-square" style={{fontSize: "32px"}}></i>
                            &nbsp;
                            &nbsp;
                            <b>Create Post</b>
                        </button>
                    )
                }
                <ul className="nav" style={{width: "inherit"}}>
                    {
                        posts.map(
                            post => <BlogNavbarButton
                                setPost={setPost}
                                postId={post?.postId}
                                title={post?.title}
                                key={keyIndex++}
                                isActive={(targetPost+1) == post?.postId}
                            />
                        )
                    }
                </ul>
            </div>
        </nav>
    );
};

const styles = {
    manuLink: {
        height: "50",
        width: "inherit",
        borderRadius: "0%"
    },
    lightBox: {
        boxShadow: "0px 0px 7px 3px #888"
    },
    homeButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}

const mapStateToProps = (state) => ({
    isAdmin: getUserData(state)?.admin
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogNavbar);