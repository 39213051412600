import React from 'react'
import { connect } from 'react-redux'
import ServiceButton from "./ServiceButton";

const data = [
    "<img src='/img/soutient.png' style='float: left' width='300px'/><p><b>Nos enseignantes et enseignants donnent un soutien scolaire individualisé aux élèves du cycle élémentaire et du cycle secondaire en fonction des besoins de l’élève. Cet accompagnement permet aux enseignantes et enseignants d’aider l’élève:</b><ul><li>à faire face à ces défis de l’élèves;</li><li>à préparer ses évaluations;</li><li>à faire ses devoirs par des explications des contenus;</li><li>préparer les examens de fin de semestre et de fin d’année;</li></ul><br/><br/><b>Nous offrons:</b><ul><li>des séances en lignes d’une heure par séance;</li><li>des séances d’urgence en ligne de 15 à 20 minutes pour répondre aux questions de nos élèves;</li><li>des séances en présentiel dans l’environnement familial ou dans la bibliothèque la plus proche de l’élève;</li><li>des horaires flexibles;</li><li>des ressources aux élèves pour permettre la continuité d’apprentissage de façon autonome.</li></ul><br/><br/><b>Nos enseignantes et enseignants sont:</b><ul><li>des membres de l\’ordre des enseignantes et enseignants de l\’Ontario;</li><li>des enseignantes et enseignants brevet du ministère de l\’éducation du Québec;</li><li>des enseignantes et enseignants qualifiés en Français Langue Seconde;</li><li>des enseignantes et enseignants qualifiés en Anglais Langue Seconde.</li></ul></p>",
    "<img src='/img/ibservice.png' style='float: right'/><p>À deux mois des examens du Baccalauréat International, nos services offrent un encadrement intensif pour la préparation des examens. Nous préparons les élèves dans la pratique des exercices types de l’examen du Baccalauréat International en mathématiques, en physique, chimie, français, anglais, biologie selon le programme cadre du baccalauréat international.<br/><br/>Pour cela nous organisons des séances individualisées ou en groupe en fonction des besoins des élèves.<br/><br/><b>Nous avons des enseignantes et enseignants certifiés du baccalauréat international qui aident:</b><ul><li>à la préparation des épreuves examens de fin année du Baccalauréat International;</li><li>la rédaction de l’évaluation interne.</li></ul></p>",
    "<div className='row'><div className='col-sm-4'><img src='/img/francais.png' style='float: right' width='150px'/></div><div className='col-sm-8'>D’après l’Université d’Ottawa, le bilinguisme offre un avantage économique, car il s'agit d'un atout sur le marché du travail. Au Canada, les travailleuses et travailleurs bilingues ont accès à un plus grand éventail de postes, notamment dans la fonction publique et les services communautaires.</div></div><br/><br/><div className='row'><div className='col-sm-4'><img src='/img/anglais.png' style='float: left' width='150px'/></div><div className='col-sm-8'>Nos services offrent des cours privés de français et d’anglais aux personnes de tous âges. Nos enseignantes et enseignants sont des enseignantes et enseignants qualifiés dans l'enseignement de Français Langue Seconde et de l’Anglais Langue Seconde.</div></div>",
    "<div className='row'><div className='col-sm-4'><img src='/img/consultation.png' style='float: left'/></div><div className='col-sm-8'> Nous offrons un service en orientation gratuite aux familles qui ont au moins un enfant inscrit dans un de nos programmes.<br/><br/>Nous accompagnons les familles dans les élèves dans leurs choix de cours au secondaire, dans leur choix d’étude collégial et universitaire.<br/><br/>Nous aidons les parents à comprendre les curriculums et les stratégies d’enseignement au Canada.<br/><br/>Les conseils de nos conseillers d’orientation et enseignant aideront les élèves dans leurs cheminement et leurs choix de carrières.</div></div>"
]

export const MobileServices = (props) => {
    return (
        <div id="section3" className="my-2" style={{paddingTop: 50}}>
            <center>
                <h3><b>Nos Services</b></h3>
                <ServiceButton title="Préparation aux examens du Baccalauréat International" data={data[1]} img="/img/ib.png"/>
                <ServiceButton title="Soutiens scolaires individuels" data={data[0]} img="/img/solo.png"/>
                <ServiceButton title="Cours privé de français et d’anglais" data={data[2]} img="/img/bilingual.png"/>
                <ServiceButton title="Service gratuit de conseil en Orientation" data={data[3]} img="/img/orientation.png"/>
                <br/>
                <small>Appuyez pour en savoir plus</small>
            </center>
        </div>
    );
};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileServices);